<template>
<b-card header="Recent Complete Service" class="mb-3" no-body border-variant="info" header-bg-variant="info" header-text-variant="white">
    <b-list-group flush>
        <b-list-group-item>
            <b-row no-gutters>
                <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                    Date
                </b-col>
                <b-col cols="8" md="4" class="text-truncate d-inline-block" >
                    Customer
                </b-col>
                <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                    City
                </b-col>
                <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                    Serviceman
                </b-col>
                <b-col cols="3" md="1" class="text-truncate d-inline-block" >
                    Catagory
                </b-col>
                <b-col cols="1" md="1">
                    
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-list-group-item v-for="tix in recentTix" :key="tix._id" :to="'/serviceTix/' + tix._id">
            <b-row no-gutters>
                <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                    {{format(fromUnixTime(tix.dates.finishedDate), 'MM/dd/yyyy')}} 
                </b-col>
                <b-col cols="8" md="4" class="text-truncate d-inline-block" >
                    <b>{{tix.customer.lname}}, {{tix.customer.fname}}</b>
                </b-col>
                <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                    {{tix.job.siteAddress.city}}
                </b-col>
                <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                    <span class="font-weight-light">
                        {{tix.serviceMan}} 
                    </span>
                </b-col>
                <b-col cols="3" md="1" class="text-truncate d-inline-block" >
                        <span class="font-weight-light">
                        {{tix.serviceCat}} 
                    </span>
                </b-col>
                <b-col cols="1" md="1">
                    <div class="float-right"><font-awesome-icon icon="chevron-right" /></div>
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</b-card>

</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
    name: 'recentServiceTix',
    data() {
        return {
            recentTix: [],
            format: format,
            fromUnixTime: fromUnixTime,
        }
    },
    components: {
        
    },
    computed: {
       userInfo() {
          return this.$state.userInfo
       }
    },
    methods: {
        async getRecentCompleteTix () {
            
            
            const res = await this.$axios.get(`/service/recentCompleted/`, {
                headers: { Authorization: `Bearer ${this.$state.token}` }
            })

            this.recentTix = res.data

        }
    },
    created() {
        //console.log(`Created called from MyRecent Widget`)
        this.getRecentCompleteTix()
    }    
}
</script>

<style lang="scss" scoped>
    
</style>