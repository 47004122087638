
<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 mb-5 pb-4' : 'mt-3' ]">
    <b-row>
      <b-col>
        <pending-service /> 
        <recent-complete-service />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import PendingService from '../components/serviceBoard-components/pendingService.vue'
import RecentCompleteService from '../components/serviceBoard-components/recentCompleteService.vue'

export default {
  name: "serviceBoard",
  components: {
    PendingService,
    RecentCompleteService
  },
  data() {
    return {
    
    }
  },
  computed: {
        userInfo () {
          return this.$state.userInfo
        },
        store () {
          return this.$state
        }, 
        isMobile () {
          return this.$state.isMobile
        }
  },
  methods: {
  
  },
  created() {
    
  }
}
</script>


<style lang="scss" scoped>

</style>
